import * as React from 'react';
import { Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import InfoCard from './Card.js';
import Grid from '@mui/material/Grid';
import TitleChange from './titleChanger.js';


    
    
    
    function BPOTOSDetails() {
        return (
            <div>
                <Grid variant='container'>
                    <Grid variant='item'>
                        <Typography variant='body1'>Big Party On The Other Side</Typography>
                    </Grid>
                    <Grid variant='item'>
                        <Typography variant='body1'>Compositions</Typography>
                    </Grid>
                    <Grid variant='item'>
                        <Typography variant='body1'>dir. Jenny Catherall</Typography>
                    </Grid>
    
                </Grid>
            </div >
    
        )
    };
    function LetterDetails() {
        return (
            <div>
                <Grid variant='container'>
                    <Grid variant='item'>
                        <Typography variant='body1'>The Letter</Typography>
                    </Grid>
                    <Grid variant='item'>
                        <Typography variant='body1'>Compositions</Typography>
                    </Grid>
                    <Grid variant='item'>
                        <Typography variant='body1'>dir. Amy Carlson/Syd Butler</Typography>
                    </Grid>
                </Grid>
            </div >
    
        )
    };
    function AllThatIsDetails() {
        return (
            <div>
                <Grid variant='container'>
                    <Grid variant='item'>
                        <Typography variant='body1'>All That Is</Typography>
                    </Grid>
                    <Grid variant='item'>
                        <Typography variant='body1'>Compositions</Typography>
                    </Grid>
                    <Grid variant='item'>
                        <Typography variant='body1'>dir. Valerie Neck</Typography>
                    </Grid>
                </Grid>
            </div >
    
        )
    };
    function NotesDetails() {
        return (
            <div>
                <Grid variant='container'>
                    <Grid variant='item'>
                        <Typography variant='body1'>Notes</Typography>
                    </Grid>
                    <Grid variant='item'>
                        <Typography variant='body1'>Compositions / Arrangements / Sound Design</Typography>
                    </Grid>
                    <Grid variant='item'>
                        <Typography variant='body1'>dir. Jimmy Olsson</Typography>
                    </Grid>
                </Grid>
            </div >
    
        )
    };
    
    function Films() {
    
    
        TitleChange("Films");
        
        return (
            <div className="App-container">            
                <Fade in mountOnEnter unmountOnExit timeout={3000}>
                    <Typography sx={{ marginTop:{xs: '7rem', md: '5rem'} }} variant="h2" color="gold">Film</Typography>
                </Fade>
                <Fade in mountOnEnter unmountOnExit timeout={3000}>
                    <Typography sx={{ marginBottom: '5rem' }} variant="h4" color="cornflowerblue">scores and sound design</Typography>
                </Fade>
                <Grid container maxWidth='90vw' spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 8, lg: 12 }} justifyContent='center' >
                    <Grid variant='item'>
                        <InfoCard MediaInfo={BPOTOSDetails}
                        cardComp='iframe'
                            textURL="https://player.vimeo.com/video/230105337?h=d5c4a2fd93"
                            textTitle="Big Party On The Other Side - dir. Jenny Catherall" />
                    </Grid>
                    <Grid variant='item'>
                        <InfoCard MediaInfo={LetterDetails}
                        cardComp='iframe'
                            textURL="https://player.vimeo.com/video/461657951?h=1bad80433b"
                            textTitle="The Letter - dir. Amy Carlson/Syd Butler" />
                    </Grid>
                    <Grid variant='item'>
                        <InfoCard MediaInfo={AllThatIsDetails}
                        cardComp='iframe'
                            textURL="https://player.vimeo.com/video/648321786?h=28abff91dd"
                            textTitle=" dir. Valerie Neck" />
                    </Grid>
                    <Grid variant='item'>
                        <InfoCard MediaInfo={NotesDetails}
                        cardComp='iframe'
                            textURL="https://player.vimeo.com/video/611861221?h=7f228fdd34"
                            textTitle="Notes - dir. Jimmy Olsson" />
                    </Grid>
                </Grid>
            </div>
        )
    };
    
    export default Films;