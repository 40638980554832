import * as React from 'react';
import { Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import InfoCard from './Card.js';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import MediaCard from './MediaCard.js';
import TitleChange from './titleChanger.js';

import HaarApp from './images/haar_app@0.5x.png';



function HaarIDetails() {
    return (
        <div>
            <Grid variant='container'>
                <Grid variant='item'>
                    <Typography variant='body1'>In meteorology, haar is a cold sea fog. It occurs most often on the east coast of Scotland between April and September, when warm air passes over the cold North Sea.</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Fraser A Campbell - clarinet, production</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Fraser A Campbell - compositions</Typography>
                </Grid>

            </Grid>
        </div >

    )
};
function HaarIIDetails() {
    return (
        <div>
            <Grid variant='container'>
            <Grid variant='item'>
                    <Typography variant='body1'>In meteorology, haar is a cold sea fog. It occurs most often on the east coast of Scotland between April and September, when warm air passes over the cold North Sea.</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Francesca Dardani - violin</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Fraser A Campbell - compositions, production</Typography>
                </Grid>
            </Grid>
        </div >

    )
};
function HaarIIIDetails() {
    return (
        <div>
            <Grid variant='container'>
            <Grid variant='item'>
                    <Typography variant='body1'>In meteorology, haar is a cold sea fog. It occurs most often on the east coast of Scotland between April and September, when warm air passes over the cold North Sea.</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Dafney - vocals</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Fraser A Campbell - compositions, production</Typography>
                </Grid>
            </Grid>
        </div >

    )
};
function HaarIVDetails() {
    return (
        <div>
            <Grid variant='container'>
            <Grid variant='item'>
                    <Typography variant='body1'>In meteorology, haar is a cold sea fog. It occurs most often on the east coast of Scotland between April and September, when warm air passes over the cold North Sea.</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>This, the fourth album in the Haar Series, is a cumulation of all three previous albums, interplaying together.</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'> Fraser A Campbell - clarinet, production</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Francesca Dardani - violin</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Dafney - vocals</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'> Fraser A Campbell - compositions</Typography>
                </Grid>
            </Grid>
        </div >

    )
};
function HaarAppDetails() {
    return (
        <div>
            <Grid variant='container' justifyContent="center"
                alignItems="center">
                <Grid variant='item' justifyContent="center"
                    alignItems="center">
                    <Typography variant='body1'>Haar is a randomly generated maze for you to explore while you listen to the tracks, Haar 14, Haar 15, Haar 1, Haar 5 and Haar 17, from the Haar Series of Albums, by Fraser A Campbell. It features four clarinets (Haar), four violins (Francesca Dardani - Haar II) and four vocals (Dafney - Haar III) that are positioned throughout the maze. As you move around, the sound of each instrument will get louder or softer depending on how close you are. Each instrument can be found on the map with a blue dot, while you, the player are represented by a Red dot.</Typography>

                </Grid>
                <Grid variant='item' justifyContent="center"
                    alignItems="center">
                    <MediaCard srcURL="https://player.vimeo.com/video/637543023" mediaTitle="Haar App"/>
                </Grid>
            </Grid>
        </div >

    )
};

function Haar() {

TitleChange("Haar Series");

    return (
        <div className="App-container">
            <Fade in mountOnEnter unmountOnExit timeout={3000}>
                <Typography sx={{ marginTop: { xs: '7rem', md: '5rem' } }} variant="h2" color="gold">Haar Series</Typography>
            </Fade>
            <Fade in mountOnEnter unmountOnExit timeout={3000}>
                <Typography variant="h4" color="cornflowerblue" sx={{ marginBottom: '5rem' }}><Link to="https://www.francescadardani.com">feat. Francesca Dardani (vln)</Link> and <Link to="https://www.dafneymusic.com">Dafney (vox)</Link></Typography>
            </Fade>
            <Grid container maxWidth='90vw' spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 8, lg: 12 }} justifyContent='center' >
                <Grid variant='item'>
                    <InfoCard MediaInfo={HaarIDetails}
                        cardComp="iframe"
                        textURL="https://bandcamp.com/EmbeddedPlayer/album=4239225603/size=large/bgcol=000000/artwork/linkcol=0687f5/transparent=true/"
                        textTitle="Haar" />
                </Grid>
                <Grid variant='item'>
                    <InfoCard MediaInfo={HaarIIDetails}
                        cardComp="iframe"
                        textURL="https://bandcamp.com/EmbeddedPlayer/album=3192492651/size=large/bgcol=000000/artwork/linkcol=0687f5/transparent=true/"
                        textTitle="Haar II - feat. Francesca Dardani" />
                </Grid>
                <Grid variant='item'>
                    <InfoCard MediaInfo={HaarIIIDetails}
                        cardComp="iframe"
                        textURL="https://bandcamp.com/EmbeddedPlayer/album=2000188796/size=large/bgcol=000000/artwork/linkcol=0687f5/transparent=true/"
                        textTitle="Haar III - feat. Dafney" />
                </Grid>
                <Grid variant='item'>
                    <InfoCard MediaInfo={HaarIVDetails}
                        cardComp="iframe"
                        textURL="https://bandcamp.com/EmbeddedPlayer/album=4165450751/size=large/bgcol=000000/artwork/linkcol=0687f5/transparent=true/"
                        textTitle="Haar Together - feat. Francesca Dardani & Dafney" />
                </Grid>
                <InfoCard
                        cardComp="img"
                        MediaInfo={HaarAppDetails}
                        imgURL={HaarApp}
                        textURL="https://apps.apple.com/us/app/haar/id1578880255"
                        textTitle="Haar App" />
            </Grid>
        </div>
    )
};

export default Haar;