import * as React from 'react';
import { Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import InfoCard from './Card.js';
import Grid from '@mui/material/Grid';
import TitleChange from './titleChanger.js';


function PoetryDetails() {
    return (
        <div>
            <Grid variant='container'>                
                <Grid variant='item'>
                    <Typography variant='body1'><a href='https://www.francescadardani.com' target='_blank' rel="noreferrer" > Francesca Dardani - violin </a></Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'><a href='https://www.davidlynch.com' target='_blank' rel="noreferrer" > David Lynch - poetry </a></Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'> Fraser A Campbell - Composition</Typography>
                </Grid>
            </Grid>
        </div >

    )
};

function Poetry() {

    TitleChange("Poetry");

    return (
        <div className="App-container">
            <Fade in mountOnEnter unmountOnExit timeout={3000}>
                <Typography sx={{ marginTop:{xs: '7rem', md: '5rem'} }} variant="h2" color="gold">Poetry</Typography>
            </Fade>
            <Fade in mountOnEnter unmountOnExit timeout={3000}>
                <Typography variant="h4" color="cornflowerblue">feat. <a target="_blank" rel="noreferrer" href="https://www.davidlynch.com">David Lynch</a></Typography>
            </Fade>
            <InfoCard MediaInfo={PoetryDetails}
            cardComp='iframe'
                textURL="https://www.youtube.com/embed/s6vBR5QRbfg"
                textTitle="Solo for Violin #1 - feat. David Lynch" />
        </div>
    )
};

export default Poetry;