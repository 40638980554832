import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';


import AchmedMY from './images/albumCovers/AchmedMY.png';
import AlterView from './images/albumCovers/AlterView.png';
import ComeOnShapes from './images/albumCovers/ComeOnShapes.png';
import CurioII from './images/albumCovers/curioII.jpg';
import Glowalker from './images/albumCovers/glowalker.png';
import GuiDisp from './images/albumCovers/GuiDisp.png';
import Haar from './images/albumCovers/haar.jpg';
import HaarII from './images/albumCovers/haarII.jpg';
import HaarIII from './images/albumCovers/haarIII.jpg';
import HaarIV from './images/albumCovers/haarIV.jpg';
import JennahBell from './images/albumCovers/JennahBell.png';
import LilyFrost from './images/albumCovers/LilyFrost.png';
import MadMaxMY from './images/albumCovers/MadMaxMY.png';
import Magana from './images/albumCovers/magana.png';
import Nona from './images/albumCovers/nona.png';
import ParadigmRefrain from './images/albumCovers/ParadigmRefrain.png';
import PSC from './images/albumCovers/poetshutsclock.jpg';
import Rilan from './images/albumCovers/Rilan.png';
import RobCharl from './images/albumCovers/RobCharl.png';
import SA from './images/albumCovers/SA.png';
import SimpleMan from './images/albumCovers/SimpleMan.png';
import SiobhanMiller from './images/albumCovers/SiobhanMiller.png';
import SunriseMY from './images/albumCovers/sunriseMY.png';
import Xenia from './images/albumCovers/Xenia.png';


export default function TitlebarImageList() {


  const matches = useMediaQuery('(min-width:800px)');

  return (
    <Fade in mountOnEnter unmountOnExit timeout={3000}>
    <ImageList sx={{ maxWidth:'80vw', minHeight: '40vh'}} cols={matches ? 8 : 5} rows={matches ? 5 : 8}>
      {itemData.map((item) => (
        <Tooltip title={<Typography fontSize={20} color="gold" >{item.title} by {item.author}</Typography>} placement="bottom-start">
        <ImageListItem key={item.img}>
          <img
            src={`${item.img}?w=240&fit=crop&auto=format`}
            srcSet={`${item.img}?w=240&fit=crop&auto=format&dpr=2 2x`}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
        </Tooltip>
      ))}
    </ImageList>
    </Fade>
  );
}

const itemData = [
  {
    img: AchmedMY,
    title: 'The Adventures of Price Achmed',
    author: 'Morricone Youth',
  },
  {
    img: AlterView,
    title: 'Perception',
    author: 'Alter View',
  },
  {
    img: ComeOnShapes,
    title: 'Come On Shapes',
    author: 'Secret Architecture',
  },
  {
    img: CurioII,
    title: 'Curio II',
    author: 'Fraser A Campbell',
  },
  {
    img: Glowalker,
    title: 'Trouble Is',
    author: 'Glowalker',
  },
  {
    img: GuiDisp,
    title: 'Disparation',
    author: 'Guillaum Beauregard',
  },
  {
    img: Haar,
    title: 'Haar',
    author: 'Fraser A Campbell',
  },
  {
    img: HaarII,
    title: 'Haar II',
    author: 'Fraser A Campbell',
  },
  {
    img: HaarIII,
    title: 'Haar III',
    author: 'Fraser A Campbell',
  },
  {
    img: HaarIV,
    title: 'Haar IV',
    author: 'Fraser A Campbell',
  },
  {
    img: JennahBell,
    title: 'Jennah Bell',
    author: 'Jennah Bell',
  },
  {
    img: LilyFrost,
    title: `It's the love`,
    author: 'Lily Frost',
  },
  {
    img: MadMaxMY,
    title: 'Mad Max',
    author: 'Morricone Youth',
  },
  {
    img: Magana,
    title: 'you are not a morning person',
    author: 'magana',
  },
  {
    img: Nona,
    title: `It's Alright`,
    author: 'Nona',
  },
  {
    img: ParadigmRefrain,
    title: 'Leyden Jar',
    author: 'Paradigm Refrain',
  },
  {
    img: PSC,
    title: 'poet shuts clock',
    author: 'Cassani / Campbell',

  },
  {
    img: Rilan,
    title: 'Burger',
    author: 'Rilan and the Bombardiers',
  },
  {
    img: RobCharl,
    title: 'Burger',
    author: 'Robert Charlebois',
  },
  {
    img: SA,
    title: 'Self Titled',
    author: 'Secret Architecture',
  },
  {
    img: SimpleMan,
    title: 'Burger',
    author: 'Simple Man',
  },
  {
    img: SiobhanMiller,
    title: 'At this time of year',
    author: 'Siobhan Miller',
  },
  {
    img: SunriseMY,
    title: 'Sunrise',
    author: 'Morricone Youth',
  },
  {
    img: Xenia,
    title: 'Black Terry Cat',
    author: 'Xenia Rubinos',
  },
  
];