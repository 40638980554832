import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { BusCardInfo } from './busCardInfo';
import { Link } from 'react-router-dom';
import { Typography, Fade } from '@mui/material';
import TitleChange from './titleChanger.js';
import BusinessCard from './misc/FraserCampbell.vcf';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const bus = BusCardInfo();
export default function BusCard() {
    
    TitleChange("Fraser A Campbell");

    return (
        <div>
            <Fade in mountOnEnter unmountOnExit timeout={3000}>
                <Typography sx={{ marginTop: { xs: '7rem', md: '6rem' }, marginBottom:'1rem' }} variant="h4" color="cornflowerblue">Links</Typography>
            </Fade>
            <Fade in mountOnEnter unmountOnExit timeout={3000}>
            <Box sx={{ width: '100%' }}>
                <Stack sx={{marginBottom:'5rem'}} spacing={2}>
                    <Item><Link to={BusinessCard} download="Fraser-Campbell-Business-Card" target="_blank" rel="noopener noreferrer" ><Typography variant='body1' color='gold'>Contact Card</Typography></Link></Item>
                    {bus.map((b) => (
                        <Item><Link to={b.loc}><Typography variant='body1' color='gold'>{b.title}</Typography></Link></Item>
                    ))}
                </Stack>
            </Box>
            </Fade>
        </div>
    );
}