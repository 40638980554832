import * as React from 'react';
import { Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import InfoCard from './Card.js';
import Grid from '@mui/material/Grid';
import CurioIIImg from './images/albumCovers/curioII.jpg';
import TitleChange from './titleChanger.js';


function CurioIIDetails() {
    return (
        <div>
            <Typography variant='h4' color='cornflowerblue'>Personnel:</Typography>
            <Grid variant="container" sx={{ display: "flex", flexGrow: 3, flexDirection: "column" }}>
                <Grid variant="container" columnGap={3} sx={{ display: "flex", flexDirection:'row' }}>
                    <Typography variant='h6' color='gold'>String Section:</Typography>
                    <Grid variant="item">
                        <Typography variant='body1'> Violin I: <a target="_blank" href="https://www.francescadardani.com" rel="noreferrer">Francesca Dardani</a></Typography>
                    </Grid>
                    <Grid variant="item">
                        <Typography variant='body1'> Violin II: <a target="_blank" href="https://www.delaneystockli.com/" rel="noreferrer">Delaney Stöckli</a></Typography>
                    </Grid >
                    <Grid variant="item">
                        <Typography variant='body1'> Viola: <a target="_blank" href="https://www.elisefrawley.com/" rel="noreferrer">Elise Frawley</a></Typography>
                    </Grid>
                    <Grid variant="item">
                        <Typography variant='body1'> Cello: Reenat Pinchas</Typography>
                    </Grid>
                </Grid>
                <Grid variant="container" columnGap={3} sx={{ display: "flex", flexDirection:'row' }}>
                    <Typography variant='h6' color='gold' >Rhythm Section:</Typography>
                    <Grid variant="item">
                        <Typography variant='body1'> Bass: <a target="_blank" href="/" rel="noreferrer">Julian Smith</a></Typography>
                    </Grid>
                    <Grid variant="item">
                        <Typography variant='body1'> Gtr: <a target="_blank" href="http://www.jonsosin.com/" rel="noreferrer">Jon Sosin</a></Typography>
                    </Grid>
                    <Grid variant="item">
                        <Typography variant='body1'> Drums (tracks 3&4): <a target="_blank" href="https://robertogiaquinto.com/" rel="noreferrer">Roberto Giaquinto</a></Typography>
                    </Grid>
                    <Grid variant="item">
                        <Typography variant='body1'> Drums (tracks 8&12): <a target="_blank" href="https://www.moderndrummer.com/article/december-2018-xenia-rubinos-marco-buccelli/" rel="noreferrer">Marco Buccelli</a></Typography>
                    </Grid>
                    <Grid variant="item">
                        <Typography variant='body1'> Piano: Fraser A Campbell</Typography>
                    </Grid>
                    <Grid variant="item">
                        <Typography variant='body1'> Synths: Fraser A Campbell</Typography>
                    </Grid>
                </Grid>
                <Grid variant="container" columnGap={3} sx={{ display: "flex", flexDirection:'row' }}>
                    <Typography variant='h6' color='gold'>Vocal Section:</Typography>
                    <Grid variant="item">
                        <Typography variant='body1'> Lead, Soprano & Alto: <a target="_blank" href="https://www.dafneymusic.com" rel="noreferrer">Dafney (Monica Cialona)</a></Typography>
                    </Grid>
                    <Grid variant="item">
                        <Typography variant='body1'> Tenor: <a target="_blank" href="/" rel="noreferrer">Daniele Odasso</a></Typography>
                    </Grid>
                    <Grid variant="item">
                        <Typography variant='body1'> Baritone: Alastair Campbell</Typography>
                    </Grid>
                </Grid>
                <Grid variant="container" columnGap={3} sx={{ display: "flex", flexDirection:'row' }}>
                    <Typography variant='h6' color='gold'>Woodwinds & Brass:</Typography>
                    <Grid variant="item">
                        <Typography variant='body1'> Trumpet: <a target="_blank" href="http://www.hugomorenomusic.com/" rel="noreferrer">Hugo Moreno</a></Typography>
                    </Grid>
                    <Grid variant="item">
                        <Typography variant='body1'> Saxophone: Fraser A Campbell</Typography>
                    </Grid>
                    <Grid variant="item">
                        <Typography variant='body1'> Flute: Fraser A Campbell</Typography>
                    </Grid>
                    <Grid variant="item">
                        <Typography variant='body1'> Clarinet: Fraser A Campbell</Typography>
                    </Grid>
                </Grid>
                <Grid variant="container" columnGap={3} sx={{ display: "flex", flexDirection:'row' }} >
                    <Typography variant='h6' color='gold'>Recording:</Typography>
                    <Grid variant="item">
                        <Typography variant='body1'> Strings: <a target="_blank" href="https://www.jefffettig.com/" rel="noreferrer">Jeff Fettig (The Creamery)</a></Typography>
                    </Grid>
                    <Grid variant="item">
                        <Typography variant='body1'> Piano: <a target="_blank" href="https://soundbetter.com/profiles/206967-juan-%22saucy%22-pe%C3%B1a" rel="noreferrer">Juan Peña (Downtown)</a></Typography>
                    </Grid>
                    <Grid variant="item">
                        <Typography variant='body1'> Drums: <a target="_blank" href="http://www.vitorhirtsch.com/" rel="noreferrer">Vitor Hirtsch (Studio 42)</a></Typography>
                    </Grid>
                </Grid>
                <Grid variant="container" columnGap={3} sx={{ display: "flex", flexDirection:'row' }} >
                <Typography variant='h6' color='gold'>Production:</Typography>
                    <Grid variant="item">
                        <Typography variant='body1'> Mixing: <a target="_blank" href="http://likeminds-music.com/" rel="noreferrer">Jesse Singer &
                            Chris Soper (LikeMinds)</a></Typography>
                    </Grid>
                    <Grid variant="item">
                        <Typography variant='body1'> Mastering: <a target="_blank" href="http://peerlessmastering.net/" rel="noreferrer">Jeff Lipton & Maria Rice (Peerless Mastering)</a></Typography>
                    </Grid>
                    <Grid variant="item">
                        <Typography variant='body1'> Artwork: <a target="_blank" href="/" rel="noreferrer">Pedro Rodriguez</a></Typography>
                    </Grid>
                </Grid>
                <Grid variant="container" justifyContent='center' columnGap={3} sx={{ display: "flex" }} >
                    <Typography variant='body1'> All Compositions by Fraser A Campbell</Typography>
                    <Typography variant='body1'> Copyright: ©six1threemusic ©GreatShapesRecords 2019-23</Typography>
                </Grid>
            </Grid>
        </div>
    );
}

const albumDetails = {title: "Curio II", desc: "music for large ensemble"}

function CurioII() {

TitleChange("Curio II");

    return (
        <div className="App-container">
            <Fade in mountOnEnter unmountOnExit timeout={3000}>
                <Typography sx={{ marginTop:{xs: '7rem', md: '5rem'} }} variant="h2" color="gold">{albumDetails.title}</Typography>
            </Fade>
            <InfoCard MediaInfo={CurioIIDetails}
                cardComp="iframe"
                imgURL={CurioIIImg}
                textURL="https://bandcamp.com/EmbeddedPlayer/album=3743169888/size=large/bgcol=333333/linkcol=0f91ff/artwork/transparent=true/"
                textTitle={albumDetails.title}
                textDesc={albumDetails.desc} />
        </div>
    )
};

export default CurioII;