import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  Link, 
} from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

import { Pages } from './pagesInfo';
import { useLocation } from "react-router-dom";






export default function BottomTabs() {
  const [value, setValue] = React.useState(null);

  
  const pages = Pages();



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const location = useLocation();
  React.useEffect(() => {
    for(let i = 0; i < pages.length; i++){
      if(location.pathname === pages[i].loc){
        setValue(i);
      }
      if(location.pathname === '/'){
        setValue(null);
      }
      window.scrollTo(0, 0);
    }
console.log(location);

  }, [location, pages]);

  return (
    <AppBar position="fixed" color="transparent" sx={{ top: 'auto', bottom: 0 }}>
      <Box sx={{ width: "100%",height:{xs: '3rem', md: '4rem'}, bgcolor: 'rgba(40, 44, 52,0.8)', backdropFilter: "blur(50px)", display: 'flex', justifyContent: 'center' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile

        >
          {pages.map((page, index) => (
            <Tooltip title={page.tip} placement="top-start">
              <Tab value={index}  sx={{ color: 'gold', fontSize:{xs:'1rem', md: '1.25rem', textShadow: '3px 3px 5px rgba(0,0,0,0.5)' }}} component={Link} label={page.title} to={page.loc} />
            </Tooltip>
          ))}

        </Tabs>
      </Box>
    </AppBar>

  );
};
