import * as React from 'react';
import { Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import InfoCard from './Card.js';
import Grid from '@mui/material/Grid';
import PSCImg from './images/albumCovers/poetshutsclock.jpg';
import TitleChange from './titleChanger.js';

function MooDetails() {
    return (
        <div>
            <Grid variant='container'>
                <Grid variant='item'>
                    <Typography variant='body1'> Fraser Campbell - tenor saxophone, keyboards, production</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'><a href='https://www.robertocassani.com' target='_blank' rel="noreferrer" > Roberto Cassani - bass, vocals </a></Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'><a href='http://sunnysiderecords.com/site/release_detail?id=1138' target='_blank' rel="noreferrer" > on Sunnyside Records </a></Typography>
                </Grid>
            </Grid>
        </div >

    )
};
function CareDetails() {
    return (
        <div>
            <Grid variant='container'>
                <Grid variant='item'>
                    <Typography variant='body1'> Fraser Campbell - tenor saxophone, whistle</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'><a href='https://www.robertocassani.com' target='_blank' rel="noreferrer" > Roberto Cassani - bass, vocals, lyrics </a></Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'><a href='https://www.rossainslie.com' target='_blank' rel="noreferrer" > Ross Ainslie - whistles </a></Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'><a href='https://www.graemestephen.net' target='_blank' rel="noreferrer" > Graeme Stephen - guitar </a></Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'><a href='https://www.allaboutjazz.com/musicians/doug-hough' target='_blank' rel="noreferrer" > Doug Hough - drums </a></Typography>
                </Grid>
            </Grid>
        </div >

    )
};

function Moo() {

    TitleChange("Cassani / Campbell");

    return (
        <div className="App-container">
            <Fade in mountOnEnter unmountOnExit timeout={3000}>
                <Typography sx={{ marginTop: { xs: '7rem', md: '5rem' } }} variant="h2" color="gold">Cassani / Campbell</Typography>
            </Fade>
            <Grid container maxWidth='90vw' spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 8, lg: 12 }} justifyContent='center' >
                <Grid variant='item'>
                    <InfoCard MediaInfo={CareDetails}
                        cardComp="iframe"
                        imgURL={PSCImg}
                        textURL="https://bandcamp.com/EmbeddedPlayer/album=482709498/size=large/bgcol=000000/artwork/linkcol=0687f5/transparent=true/"
                        textTitle="Cassani / Campbell - Care (Very Much)" />
                </Grid>
                <Grid variant='item'>
                    <InfoCard MediaInfo={MooDetails}
                        cardComp="iframe"
                        imgURL={PSCImg}
                        textURL="https://bandcamp.com/EmbeddedPlayer/album=588411453/size=large/bgcol=000000/artwork/linkcol=0687f5/transparent=true/"
                        textTitle="Cassani / Campbell - poet / shuts / clock" />
                </Grid>
            </Grid >
        </div >
    )
};

export default Moo;