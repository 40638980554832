import * as React from 'react';
import { Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import InfoCard from './Card.js';
import Grid from '@mui/material/Grid';
import TitleChange from './titleChanger.js';



function PyrrhusDetails() {
    return (
        <div>
            <Grid variant='container'>
                <Grid variant='item'>
                    <Typography variant='body1'>From the album Curio II</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Fraser A Campbell - compositions</Typography>
                </Grid>
            </Grid>
        </div >

    )
};
function GoodnessDetails() {
    return (
        <div>
            <Grid variant='container'>
                <Grid variant='item'>
                    <Typography variant='body1'>From the album poet / shuts / clock by Cassani / Campbell</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Fraser A Campbell - saxophone, compositions, production</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Roberto Cassani - bass, lyrics</Typography>
                </Grid>
            </Grid>
            <Grid variant='item'>
                    <Typography variant='body1'>out on Sunnyside Records</Typography>
                </Grid>
        </div >

    )
};
function Haar2Details() {
    return (
        <div>
            <Grid variant='container'>
            <Grid variant='item'>
                    <Typography variant='body1'>From the album Haar III</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Dafney - vocals</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Fraser A Campbell - compositions, production</Typography>
                </Grid>
            </Grid>
        </div >

    )
};
function ComfyKleeDetails() {
    return (
        <div>
            <Grid variant='container'>
                <Grid variant='item'>
                    <Typography variant='body1'>From the album poet / shuts / clock by Cassani / Campbell</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Fraser A Campbell - saxophone, compositions, production</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Roberto Cassani - bass</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>out on Sunnyside Records</Typography>
                </Grid>
            </Grid>
        </div >

    )
};
function Haar1Details() {
    return (
        <div>
            <Grid variant='container'>
                <Grid variant='item'>
                    <Typography variant='body1'>From the album Haar II</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>feat. Francesca Dardani - violin</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'> Fraser A Campbell - compositions, production</Typography>
                </Grid>
            </Grid>
        </div >

    )
};
function LookAtHimDetails() {
    return (
        <div>
            <Grid variant='container'>
                <Grid variant='item'>
                    <Typography variant='body1'>From the album poet / shuts / clock by Cassani / Campbell</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Fraser A Campbell - saxophone, compositions, production</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Roberto Cassani - bass, lyrics</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>out on Sunnyside Records</Typography>
                </Grid>
            </Grid>
        </div >

    )
};
function MooDetails() {
    return (
        <div>
            <Grid variant='container'>
                <Grid variant='item'>
                    <Typography variant='body1'>From the album poet / shuts / clock by Cassani / Campbell</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Fraser A Campbell - saxophone, compositions, production</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Roberto Cassani - bass, lyrics</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>out on Sunnyside Records</Typography>
                </Grid>
            </Grid>
        </div >

    )
};
function LAmoreDetails() {
    return (
        <div>
            <Grid variant='container'>
                <Grid variant='item'>
                    <Typography variant='body1'>From the album poet / shuts / clock by Cassani / Campbell</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Fraser A Campbell - saxophone, compositions, production</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Roberto Cassani - bass, lyrics</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>out on Sunnyside Records</Typography>
                </Grid>
            </Grid>
        </div >

    )
};
function CrepDetails() {
    return (
        <div>
            <Grid variant='container'>
                <Grid variant='item'>
                    <Typography variant='body1'>From the album Curio II</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Fraser A Campbell - compositions</Typography>
                </Grid>
            </Grid>
        </div >

    )
};
function Haar14Details() {
    return (
        <div>
            <Grid variant='container'>
                <Grid variant='item'>
                    <Typography variant='body1'>From the album Haar</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'> Fraser A Campbell - clarinet, compositions, production</Typography>
                </Grid>
            </Grid>
        </div >

    )
};

function Vids() {

    TitleChange("Music Videos");

    return (
        <div className="App-container">
            <Fade in mountOnEnter unmountOnExit timeout={3000}>
                <Typography sx={{ marginTop:{xs: '7rem', md: '5rem'}, marginBottom: '5rem' }} variant="h2" color="gold">Music Videos</Typography>
            </Fade>
            <Grid container maxWidth='90vw' spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 8, lg: 12 }} justifyContent='center' >
                <Grid variant='item'>
                    <InfoCard MediaInfo={PyrrhusDetails}
                        cardComp="iframe"
                        textURL="https://www.youtube.com/embed/BdKgTZsd4Bc"
                        textTitle="Pyrrhus from Curio II (360° Video)" />
                </Grid>
                <Grid variant='item'>
                    <InfoCard MediaInfo={GoodnessDetails}
                        cardComp="iframe"
                        textURL="https://www.youtube.com/embed/AU9G15n3ah0"
                        textTitle="Cassani / Campbell - Goodness of the Human Heart" />
                </Grid>
                <Grid variant='item'>
                    <InfoCard MediaInfo={Haar2Details}
                        cardComp="iframe"
                        textURL="https://www.youtube.com/embed/ixa5P0kTp4s"
                        textTitle="Haar 2 from Haar III feat. Dafney (vox)" />
                </Grid>
                <Grid variant='item'>
                    <InfoCard MediaInfo={ComfyKleeDetails}
                        cardComp="iframe"
                        textURL="https://www.youtube.com/embed/8btjnp3zykU"
                        textTitle="Cassani / Campbell - Comfy Klee" />
                </Grid>
                <Grid variant='item'>
                    <InfoCard MediaInfo={Haar1Details}
                        cardComp="iframe"
                        textURL="https://www.youtube.com/embed/KFIvi959W2g"
                        textTitle="Haar 1 from Haar II feat. Francesca Dardani (vln) (360° Video)" />
                </Grid>
                <Grid variant='item'>
                    <InfoCard MediaInfo={MooDetails}
                        cardComp="iframe"
                        textURL="https://www.youtube.com/embed/CSZ1LYfilvg"
                        textTitle="Cassani / Campbell - Moo!" />
                </Grid>
                <Grid variant='item'>
                    <InfoCard MediaInfo={CrepDetails}
                        cardComp="iframe"
                        textURL="https://www.youtube.com/embed/xXX7dvHezQ8"
                        textTitle="Crepuscular Rays from Curio II" />
                </Grid>
                <Grid variant='item'>
                    <InfoCard MediaInfo={Haar14Details}
                        cardComp="iframe"
                        textURL="https://player.vimeo.com/video/424429834?h=80eb96ae3c"
                        textTitle="Haar 14 from Haar" />
                </Grid>
                <Grid variant='item'>
                    <InfoCard MediaInfo={LookAtHimDetails}
                        cardComp="iframe"
                        textURL="https://www.youtube.com/embed/3HR3CkeLbFc"
                        textTitle="Cassani / Campbell - Look At Him" />
                </Grid>                
                <Grid variant='item'>
                    <InfoCard MediaInfo={LAmoreDetails}
                        cardComp="iframe"
                        textURL="https://www.youtube.com/embed/e8uHBYgBk0M"
                        textTitle="Cassani / Campbell - L'Amore" />
                </Grid>
                
                
            </Grid>
        </div>
    )
};

export default Vids;