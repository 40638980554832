import * as React from 'react';
import { Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import InfoCard from './Card.js';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import TitleChange from './titleChanger.js';



function QuantumDetails() {
    return (
        <div>
            <Grid variant='container'>
                <Grid variant='item'>
                    <Typography variant='body1'>Demo of the Quantum Sample Library/Engine</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Fraser A Campbell - composition, production</Typography>
                </Grid>

            </Grid>
        </div >
    )
};
function CelloDetails() {
    return (
        <div>
            <Grid variant='container'>
                <Grid variant='item'>
                    <Typography variant='body1'>Demo of the Cello Textures Sample Library/Engine</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Fraser A Campbell - composition, production</Typography>
                </Grid>
            </Grid>
        </div >
    )
};
function ViolinDetails() {
    return (
        <div>
            <Grid variant='container'>
                <Grid variant='item'>
                    <Typography variant='body1'>Demo of the Violin Textures Sample Library/Engine</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Fraser A Campbell - composition, production</Typography>
                </Grid>
            </Grid>
        </div >
    )
};

function ClarinetDetails() {
    return (
        <div>
            <Grid variant='container'>
                <Grid variant='item'>
                    <Typography variant='body1'>Demo of the Clarinet Textures Sample Library/Engine</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Fraser A Campbell - composition and guest artist on Clarinet</Typography>
                </Grid>
            </Grid>
        </div >
    )
};

function Endorsements() {

    TitleChange("Endorsements");

    return (
        <div className="App-container">
            <Fade in mountOnEnter unmountOnExit timeout={3000}>
                <Typography sx={{ marginTop:{xs: '7rem', md: '5rem'} }} variant="h2" color="gold">Endorsements with</Typography>
            </Fade>
            <Fade in mountOnEnter unmountOnExit timeout={3000}>
                <Typography variant="h4" color="cornflowerblue" sx={{ marginBottom: '5rem' }}><Link to="https://www.emergenceaudio.com">Emergence Audio</Link></Typography>
            </Fade>
            <Grid container maxWidth='90vw' spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 8, lg: 12 }} justifyContent='center' >
                <Grid variant='item'>
                    <InfoCard MediaInfo={QuantumDetails}
                        cardComp="iframe"
                        textURL="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1022167498&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                        textTitle="Quantum" />
                </Grid>
                <Grid variant='item'>
                    <InfoCard MediaInfo={CelloDetails}
                        cardComp="iframe"
                        textURL="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1276337185&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                        textTitle="Cello Textures" />
                </Grid>
                <Grid variant='item'>
                    <InfoCard MediaInfo={ViolinDetails}
                        cardComp="iframe"
                        textURL="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1206694471&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                        textTitle="Violin Textures" />
                </Grid>
                <Grid variant='item'>
                    <InfoCard MediaInfo={ClarinetDetails}
                        cardComp="iframe"
                        textURL="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1458117376&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                        textTitle="Clarinet Textures" />
                </Grid>
            </Grid>
        </div>
    )
};

export default Endorsements;