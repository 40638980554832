import * as React from 'react';
import { Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import Container from '@mui/material/Container';
import TitlebarBelowImageList from './ImageList.js';
import TitleChange from './titleChanger.js';

function Albums() {

    TitleChange("Albums");

    return (
        <div>
        <Fade in mountOnEnter unmountOnExit timeout={3000}> 
            <Typography sx={{ marginTop:{xs: '7rem', md: '5rem'} }} variant="h2" color="gold">Albums</Typography>
        </Fade>
        <Container>
            <TitlebarBelowImageList maxWidth="80rem" />
        </Container>
        </div>
        
    )
};

export default Albums;