import * as React from 'react';
import './App.css';
import { ThemeProvider, createTheme, responsiveFontSizes, } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ResponsiveAppBar from './AppBar.js';
import { Routes, Route, Link } from "react-router-dom";
import { Typography } from '@mui/material';
import BottomTabs from './BottomTabs';



import Home from './home';
import CurioII from './curioII';
import Moo from './moo';
import Haar from './haar';
import Arr from './arr';
import FacApps from './facapps';
import Albums from './albums';
import Films from './filum';
import Ads from './ads';
import ARXR from './arxr';
import Endorsements from './end';
import Dance from './dance';
import Poetry from './poetry';
import Vids from './vids';
import Links from './links';
import BusCard from './buscard';





function Main() {
  return(
    <Routes>       
      <Route path='/' element={<Home />} />      
      <Route path='/curioII' element={<CurioII />}/>  
      <Route path='/moo' element={<Moo />}/>
      <Route path='/haar' element={<Haar />}/>
      <Route path='/arr' element={<Arr />}/>
      <Route path='/poetry' element={<Poetry />}/>  
      <Route path='/apps' element={<FacApps />}/>
      <Route path='/films' element={<Films />}/>
      <Route path='/albums' element={<Albums />}/>
      <Route path='/vids' element={<Vids />}/>
      <Route path='/dance' element={<Dance />}/>          
      <Route path='/end' element={<Endorsements />}/>
      <Route path='/arxr' element={<ARXR />}/>
      <Route path='/ads' element={<Ads />}/>
      <Route path='/links' element={<Links />}/>
      <Route path='/buscard' element={<BusCard />}/>
      <Route path="*" element={<NoMatch />} />    
    </Routes>
  );
}

function NoMatch() {
  return (
    <div className="App-container">
    <div className="titleTxt">
      <Typography variant='h3'>Nothing to see here!</Typography>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
    </div>
  );
}


function App() {

  let theme = createTheme({
        typography: {
          fontFamily:[
            'Zen Maru Gothic',
            'sans-serif',
          ].join(','),   
               
        },
        palette: {
          mode: 'dark',
        },
      });
    
  theme = responsiveFontSizes(theme);
  









  return (
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <div className="App" >
    <ResponsiveAppBar />
    <div className="App-container">
      <Main />
    </div>
    <BottomTabs />
    </div>
    </ThemeProvider>
  );
}

export default App;
