import * as React from 'react';
import { Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import InfoCard from './Card.js';
import Grid from '@mui/material/Grid';
import MediaCard from './MediaCard.js';
import TitleChange from './titleChanger.js';

import HaarApp from './images/haar_app@0.5x.png';
import CurioApp from './images/collage_app@0.5x.png';


function HaarDetails() {
    return (
        <div>
            <Grid variant='container' justifyContent="center"
                alignItems="center">
                <Grid variant='item' justifyContent="center"
                    alignItems="center">
                    <Typography variant='body1'>Haar is a randomly generated maze for you to explore while you listen to the tracks, Haar 14, Haar 15, Haar 1, Haar 5 and Haar 17, from the Haar Series of Albums, by Fraser A Campbell. It features four clarinets (Haar), four violins (Francesca Dardani - Haar II) and four vocals (Dafney - Haar III) that are positioned throughout the maze. As you move around, the sound of each instrument will get louder or softer depending on how close you are. Each instrument can be found on the map with a blue dot, while you, the player are represented by a Red dot.</Typography>

                </Grid>
                <Grid variant='item' justifyContent="center"
                    alignItems="center">
                    <MediaCard srcURL="https://player.vimeo.com/video/637543023" mediaTitle="Haar App"/>
                </Grid>
            </Grid>
        </div >

    )
};
function CurioDetails() {
    return (
        <div>
            <Grid variant='container' justifyContent="center"
                alignItems="center">
                <Grid variant='item' justifyContent="center"
                    alignItems="center">
                    <Typography variant='body1'>Curio Collage is a multimedia app for creating musical compositions in the form of video collages. Tap a square to play and pause the videos, controlling the order to create the piece. Shake the device at any time to return to the main menu.</Typography>
                </Grid>
                <Grid variant='item' justifyContent="center"
                    alignItems="center">
                    <MediaCard srcURL="https://player.vimeo.com/video/647726728" mediaTitle ="Curio Collage App" />
                </Grid>
            </Grid>
        </div >

    )
};

function PyrrhusDetails() {
    return (
        <div>
            <Grid variant='container'>
                <Grid variant='item'>
                    <Typography variant='body1'>From the album Curio II</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Fraser A Campbell - compositions</Typography>
                </Grid>
            </Grid>
        </div >

    )
};



function ARXR() {

    TitleChange("AR / XR");

    return (
        <div className="App-container">
            <Fade in mountOnEnter unmountOnExit timeout={3000}>
                <Typography sx={{ marginTop: { xs: '7rem', md: '5rem' }, marginBottom: '5rem' }} variant="h2" color="gold">AR / XR</Typography>
            </Fade>
            <Grid container maxWidth='90vw' spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 8, lg: 12 }} justifyContent='center' >
                <Grid variant='item'>
                    <InfoCard
                        cardComp="img"
                        MediaInfo={HaarDetails}
                        imgURL={HaarApp}
                        textURL="https://apps.apple.com/us/app/haar/id1578880255"
                        textTitle="Haar App" />
                </Grid>
                <Grid variant='item'>
                    <InfoCard
                        cardComp="img"
                        MediaInfo={CurioDetails}
                        imgURL={CurioApp}
                        textURL="https://apps.apple.com/us/app/curio-collage/id1586493610"
                        textTitle="Curio Collage" />
                </Grid>
                <Grid variant='item'>
                    <InfoCard MediaInfo={PyrrhusDetails}
                        cardComp="iframe"
                        textURL="https://www.youtube.com/embed/BdKgTZsd4Bc"
                        textTitle="Pyrrhus from Curio II (360° Video)" />
                </Grid>
            </Grid>
        </div>
    )
};

export default ARXR;