import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';

import { Pages } from './pagesInfo';






function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };


  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const pages = Pages();

  return (
    <AppBar position="fixed" color="transparent"  enableColorOnDark sx={{backdropFilter:"blur(25px)"}}>
      <Container maxWidth="xl"  >
        <Toolbar disableGutters sx={{  display: 'flex', justifyContent: 'space-between'}}>      
          <Box sx={{display: 'flex' }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{color: 'gold'}}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: 'flex',
                flexDirection:'column'
              }}
            >
              {pages.map((page) => (                
                <MenuItem component={Link} to={page.loc} onClick={handleCloseNavMenu}>
                  <Typography color='gold' textAlign="center">{page.title}</Typography>                
                </MenuItem>                
              ))}
            </Menu>
          </Box>
          <Container>
          <Typography
            variant="h4"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 3,
              display: 'flex',
              letterSpacing: '.3rem',
              textShadow: '3px 3px 5px rgba(0,0,0,0.5)',
              color: 'gold',
              textDecoration: 'none',
              justifyContent: 'center',
              paddingBottom: '0.5rem'
            }}
          >
            Fraser A Campbell
          </Typography>
          <Typography
            variant="h5"            
            component={Link}
            to="/"
            sx={{
              mr: 3,
              display: 'flex',
              letterSpacing: '.3rem',
              textShadow: '3px 3px 5px rgba(0,0,0,0.5)',
              color: 'cornflowerblue',
              textDecoration: 'none',
              justifyContent: 'center',
              paddingBottom: '0.5rem'
            }}
          >
            composer | arranger | woodwinder
          </Typography>
          </Container>
          
          <Tooltip title="Contact Fraser" placement="bottom-start">
          <IconButton size='large' sx={{textDecoration:'none', color: 'gold'}} href="mailto:info@fraser-campbell.com?subject=hello!">
          <MailOutlineIcon  />
          </IconButton>
          </Tooltip>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
