import { Typography } from "@mui/material";
import Fade from '@mui/material/Fade';
import TitleChange from './titleChanger.js';


function Home() {

TitleChange("Fraser A Campbell");
    return (
        <div>
            <Fade in mountOnEnter unmountOnExit timeout={3000}>
                <Typography variant="h2" color="gold" sx={{ textShadow: '3px 3px 5px rgba(0,0,0,0.5)' }}>Fraser A Campbell</Typography>
            </Fade>
            <Fade in mountOnEnter unmountOnExit timeout={3000}>
                <Typography variant="h3" color="cornflowerblue" sx={{ textShadow: '3px 3px 5px rgba(0,0,0,0.5)' }}>composer | arranger | woodwinder</Typography>
            </Fade>
        </div>
    )
};

export default Home;