import * as React from 'react';
import { Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import InfoCard from './Card.js';
import Grid from '@mui/material/Grid';
import TitleChange from './titleChanger.js';


    
    
    
    function TMITHC1Details() {
        return (
            <div>
                <Grid variant='container'>
                    <Grid variant='item'>
                        <Typography variant='body1'>The Man In The High Castle / Amazon x The Guardian - Part 1</Typography>
                    </Grid>
                    <Grid variant='item'>
                        <Typography variant='body1'>Compositions</Typography>
                    </Grid>    
                </Grid>
            </div >
    
        )
    };
    function TMITHC2Details() {
        return (
            <div>
                <Grid variant='container'>
                    <Grid variant='item'>
                        <Typography variant='body1'>The Man In The High Castle / Amazon x The Guardian - Part 2</Typography>
                    </Grid>
                    <Grid variant='item'>
                        <Typography variant='body1'>Compositions</Typography>
                    </Grid>
                </Grid>
            </div >
    
        )
    };
    function TMITHC3Details() {
        return (
            <div>
                <Grid variant='container'>
                    <Grid variant='item'>
                        <Typography variant='body1'>The Man In The High Castle / Amazon x The Guardian - Part 3</Typography>
                    </Grid>
                    <Grid variant='item'>
                        <Typography variant='body1'>Compositions / Arrangements / Sound Design</Typography>
                    </Grid>
                </Grid>
            </div >
    
        )
    };
    function SamsungDetails() {
        return (
            <div>
                <Grid variant='container'>
                    <Grid variant='item'>
                        <Typography variant='body1'>Samsung x USA Basketball (360° Video)</Typography>
                    </Grid>
                    <Grid variant='item'>
                        <Typography variant='body1'>Compositions / Arrangements / Sound Design</Typography>
                    </Grid>
                </Grid>
            </div >
    
        )
    };
    
    function Ads() {
    
        TitleChange("Ads");
    
        return (
            <div className="App-container">            
                <Fade in mountOnEnter unmountOnExit timeout={3000}>
                    <Typography sx={{ marginTop:{xs: '7rem', md: '5rem'} }} variant="h2" color="gold">Ads</Typography>
                </Fade>
                <Fade in mountOnEnter unmountOnExit timeout={3000}>
                    <Typography sx={{ marginBottom: '5rem' }} variant="h4" color="cornflowerblue">bespoke composition and sound design</Typography>
                </Fade>
                <Grid container maxWidth='90vw' spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 8, lg: 12 }} justifyContent='center' >
                    <Grid variant='item'>
                        <InfoCard MediaInfo={TMITHC1Details}
                        cardComp='iframe'
                            textURL="https://labs.theguardian.com/amazon-mithc/film-1-war/build-120816-launch-2/player.html"
                            textTitle="The Man In The High Castle / Amazon x The Guardian - Part 1" />
                    </Grid>
                    <Grid variant='item'>
                        <InfoCard MediaInfo={TMITHC2Details}
                        cardComp='iframe'
                            textURL="https://labs.theguardian.com/amazon-mithc/film-2-propaganda/build-121216-5/player.html"
                            textTitle="The Man In The High Castle / Amazon x The Guardian - Part 2" />
                    </Grid>
                    <Grid variant='item'>
                        <InfoCard MediaInfo={TMITHC3Details}
                        cardComp='iframe'
                            textURL="https://labs.theguardian.com/amazon-mithc/film-3-culture/build-121516/player.html"
                            textTitle="The Man In The High Castle / Amazon x The Guardian - Part 3" />
                    </Grid>
                    <Grid variant='item'>
                        <InfoCard MediaInfo={SamsungDetails}
                        cardComp='iframe'
                            textURL="https://www.youtube.com/embed/K8BjQgO_6wY"
                            textTitle="Samsung x USA Basketball (360° Video)" />
                    </Grid>
                </Grid>
            </div>
        )
    };
    
    export default Ads;