import * as React from 'react';
import { Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import InfoCard from './Card.js';
import Grid from '@mui/material/Grid';
import TitleChange from './titleChanger.js';


function PhantDetails() {
    return (
        <div>
            <Grid variant='container'>
                <Grid variant='item'>
                    <Typography variant='body1'>Phantasmagoria</Typography>
                </Grid>                
                <Grid variant='item'>
                    <Typography variant='body1'>Delaney Stockli - Violin</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Jim Funnell - Piano</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Akiko Hari - Percussion</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Maya Orchin - Choreography</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Fraser A Campbell - Clarinet, Composition</Typography>
                </Grid>
            </Grid>
        </div >

    )
};
function TransFadeDetails() {
    return (
        <div>
            <Grid variant='container'>
                <Grid variant='item'>
                    <Typography variant='body1'>Transport Fade</Typography>
                </Grid>                
                <Grid variant='item'>
                    <Typography variant='body1'>Delaney Stockli - Composition, Violin, Production</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Maya Orchin - Choreography</Typography>
                </Grid>
                <Grid variant='item'>
                    <Typography variant='body1'>Fraser A Campbell - Composition, Woodwinds, Production</Typography>
                </Grid>
            </Grid>
        </div >

    )
};


function Dance() {

TitleChange("Dance");

    return (
        <div className="App-container">            
            <Fade in mountOnEnter unmountOnExit timeout={3000}>
                <Typography sx={{ marginTop: {xs: '7rem', md: '5rem'} , marginBottom:'5rem' }} variant="h2" color="gold">Dance</Typography>
            </Fade>
            <Grid container maxWidth='90vw' spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 6, md: 8, lg: 12 }} justifyContent='center' >
                <Grid variant='item'>
                    <InfoCard MediaInfo={PhantDetails}
                    cardComp='iframe'
                        textURL="https://player.vimeo.com/video/200411860"
                        textTitle="Phantasmagoria" />
                </Grid>
                <Grid variant='item'>
                    <InfoCard MediaInfo={TransFadeDetails}
                    cardComp='iframe'
                        textURL="https://player.vimeo.com/video/193950872"
                        textTitle="Transport Fade" />
                </Grid>
            </Grid>
        </div>
    )
};

export default Dance;