import * as React from 'react';
import { Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import InfoCard from './Card.js';
import Grid from '@mui/material/Grid';
import TitleChange from './titleChanger.js';


function ArrDetails() {
    return (
        <div>
            <Grid variant='container'>
                <Grid variant='item'>
                    <Typography variant='body1'>String and Woodwind arrangements commissioned by a variety of songwriters, bands and producers</Typography>
                </Grid>                
            </Grid>
        </div >

    )
};

function Arr() {

    TitleChange("Arranging");

    return (
        <div className="App-container">
            <Fade in mountOnEnter unmountOnExit timeout={3000}>
                <Typography sx={{ marginTop:{xs: '7rem', md: '5rem'} }} variant="h2" color="gold">Arrangments</Typography>
            </Fade>
            <Fade in mountOnEnter unmountOnExit timeout={3000}>
                <Typography variant="h4" color="cornflowerblue">Strings & Woodwinds</Typography>
            </Fade>
            <InfoCard cardComp="iframe" MediaInfo={ArrDetails}
                textURL="https://www.youtube.com/embed/videoseries?list=PLAqcPvdFAg6CJ4Qj1cZc8D9Y8GpbR_nP7"
                textTitle="Fraser A Campbell - Arrangements" />
        </div>
    )
};

export default Arr;