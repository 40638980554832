import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';


export default function MediaCard({srcURL, mediaTitle}) {
  return (
    <Card sx={{ maxWidth: '90%'}}>
      <CardMedia      
      component="iframe"
        src={srcURL}
        title={mediaTitle}
      />
    </Card>
  );
}